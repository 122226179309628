import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import { loginRequest } from "../../authConfig";
import axios from "axios";

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, handleFormData, values }) => {
	//creating error state for validation
	const [error, setError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [nameSuccess, setNameSuccess] = useState(false);
	const [nameLoading, setNameLoading] = useState(false);
	const { instance, accounts } = useMsal();

	function checkProjectName() {
		setNameLoading(true);
		setNameError(false);
		setNameSuccess(false);

		//values.projectName
		window.addEventListener("beforeunload", (ev) => {
			ev.preventDefault();
			return (ev.returnValue = "We are not finish creating everything!");
		});

		const request = {
			...loginRequest,
			account: accounts[0]
		};

		// Silently acquires an access token which is then attached to a request for Microsoft Graph data
		instance
			.acquireTokenSilent(request)
			.then(async (msal) => {
				// Check project
				const headers = {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${msal.accessToken}`
				};

				await axios
					.get(
						`${process.env.REACT_APP_DEVOPS_URL}/_apis/projects/${values.projectName}`,
						{
							headers: headers
						}
					)
					.then(() => {
						setNameLoading(false);
						setNameError(true);
						setNameSuccess(false);
						return false;
					})
					.catch(() => {
						setNameLoading(false);
						setNameError(false);
						setNameSuccess(true);
						return true;
					});
			})
			.catch((e) => {
				console.log(e);
			});
	}

	// after form submit validating the form data using validator
	const submitFormData = (e) => {
		e.preventDefault();

		if (validator.isEmpty(values.projectName) || validator.isEmpty(values.projectDescription)) {
			setError(true);
		} else {
			nextStep();
		}
	};

	return (
		<div>
			<Card>
				<Card.Body>
					<h3 className="headline">Project info</h3>
					<p>Lets start with some basic information about the project</p>
					<hr />
					<Form className="form" onSubmit={submitFormData}>
						<Form.Group className="mb-3">
							<Form.Label>What is the name of your project</Form.Label>
							{nameLoading ? (
								<div className="projectname-error loading">
									<div className="loading-icon"></div>
								</div>
							) : (
								""
							)}
							{nameError ? (
								<div className="projectname-error error">
									<div className="error-icon"></div>
								</div>
							) : (
								""
							)}
							{nameSuccess ? (
								<div className="projectname-error success">
									<div className="success-icon"></div>
								</div>
							) : (
								""
							)}

							<Form.Control
								style={{ border: error ? "2px solid red" : "" }}
								name="projectName"
								defaultValue={values.projectName}
								type="text"
								placeholder="Project name"
								onChange={handleFormData("projectName")}
								onBlur={checkProjectName}
							/>
							{nameError ? (
								<Form.Text style={{ color: "red" }}>
									The project name is already in use
								</Form.Text>
							) : error ? (
								<Form.Text style={{ color: "red" }}>
									This is a required field
								</Form.Text>
							) : (
								""
							)}
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Could you shortly describe the project</Form.Label>
							<Form.Control
								style={{ border: error ? "2px solid red" : "" }}
								name="projectDescription"
								defaultValue={values.projectDescription}
								type="text"
								as="textarea"
								rows={5}
								placeholder="Project description"
								onChange={handleFormData("projectDescription")}
							/>
							{nameError ? (
								""
							) : error ? (
								<Form.Text style={{ color: "red" }}>
									This is a required field
								</Form.Text>
							) : (
								""
							)}
						</Form.Group>
						<div className="buttonContainer">
							<Button variant="primary" type="submit">
								Continue
							</Button>
						</div>
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
};

export default StepOne;
