import axios from "axios";
import { runProgress } from "../Progress";
import { createRelease } from "./Release";

export async function appendDeploymentPool(
	accessToken,
	project,
	testServer,
	repoId,
	projectId,
	buildId,
	objectId,
	account
) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/deployment/append/${project.name}/${testServer}`,
			{},
			{ headers: headers }
		)
		.then(() => {
			var loading = document.querySelector(".loading.deployment");
			loading.classList.remove("loading");
			loading.classList.add("success");
			loading.querySelector(".loading-icon").classList.add("success-icon");
			loading.querySelector(".loading-icon").classList.remove("loading-icon");

			runProgress(0.5);

			setTimeout(() => {
				loading.classList.add("d-none");
				var loading2 = document.querySelector(".loading.release");
				loading2.classList.remove("d-none");
			}, 1500);

			getDeploymentGroup(accessToken, project, repoId, projectId, buildId, objectId, account);
		});
}

export async function getDeploymentGroup(
	accessToken,
	project,
	repoId,
	projectId,
	buildId,
	objectId,
	account
) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.get(`${process.env.REACT_APP_WEB_API}/deployment/group/${project.name}`, {
			headers: headers
		})
		.then((response) => {
			createRelease(
				accessToken,
				project,
				response.data.id,
				repoId,
				projectId,
				buildId,
				objectId,
				account
			);
		});
}
