import axios from "axios";
import { runProgress } from "../Progress";
import { getRepository } from "./Repository";

export async function importProject(accessToken, project, repoId, projectId, account) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/git/import/${project.name}/${repoId}/${projectId}`,
			{},
			{ headers: headers }
		)
		.then(() => {
			var loading = document.querySelector(".loading.repo");
			loading.classList.remove("loading");
			loading.classList.add("success");
			loading.querySelector(".loading-icon").classList.add("success-icon");
			loading.querySelector(".loading-icon").classList.remove("loading-icon");

			runProgress(0.25);

			setTimeout(() => {
				loading.classList.add("d-none");
				var loading2 = document.querySelector(".loading.build");
				loading2.classList.remove("d-none");
			}, 1500);

			getRepository(accessToken, project, repoId, projectId, account);
		});
}
