import axios from "axios";
import { appendDeploymentPool } from "./Deployment";
import { runProgress } from "../Progress";

export async function createBuild(accessToken, project, repoId, projectId, objectId, account) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/build/create/${project.name}/${repoId}`,
			{},
			{ headers: headers }
		)
		.then((response) => {
			var loading = document.querySelector(".loading.build");
			loading.classList.remove("loading");
			loading.classList.add("success");
			loading.querySelector(".loading-icon").classList.add("success-icon");
			loading.querySelector(".loading-icon").classList.remove("loading-icon");

			runProgress(0.375);

			setTimeout(() => {
				loading.classList.add("d-none");
				var loading2 = document.querySelector(".loading.deployment");
				loading2.classList.remove("d-none");
			}, 1500);

			// Append to deployment pool
			appendDeploymentPool(
				accessToken,
				project,
				"azure-test001",
				repoId,
				projectId,
				response.data,
				objectId,
				account
			);
		});
}

export async function runBuild(accessToken, project) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/build/run`,
			{
				projectName: project.name
			},
			{ headers: headers }
		)
		.then(() => {
			var loading = document.querySelector(".loading.create-database");
			if (loading !== null) {
				loading.classList.remove("loading");
				loading.classList.add("success");
				loading.querySelector(".loading-icon").classList.add("success-icon");
				loading.querySelector(".loading-icon").classList.remove("loading-icon");

				runProgress(1);
				setTimeout(() => {
					// remove loading overlay
					document.querySelector(".loading-overlay").remove();

					document.querySelector(".js-final-btn").click();
				}, 1500);
			}
		});
}
