import axios from "axios";
import { getProject } from "./Projects";
import { createBuild } from "./Build";
import { runProgress } from "../Progress";
import { createDatabase } from "./Database";

export async function getRepositoryId(accessToken, project, account) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.get(`${process.env.REACT_APP_WEB_API}/repository/${project.name}`, {
			headers: headers
		})
		.then((response) => {
			// Get Project Data
			getProject(accessToken, project, response.data, account);
		});
}

export async function getRepository(accessToken, project, repoId, projectId, account) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.get(`${process.env.REACT_APP_WEB_API}/repository/${project.name}/${repoId}`, {
			headers: headers
		})
		.then((response) => {
			// Create build pipeline
			createBuild(accessToken, project, repoId, projectId, response.data, account);
		});
}

export async function createBranch(accessToken, repoId, project, objectId, userId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/repository/branch/create/`,
			{
				repoId: repoId,
				fromBranch: "main",
				toBranch: "release",
				projectName: project.name
			},
			{ headers: headers }
		)
		.then(() => {
			var loading = document.querySelector(".loading.branch");
			loading.classList.remove("loading");
			loading.classList.add("success");
			loading.querySelector(".loading-icon").classList.add("success-icon");
			loading.querySelector(".loading-icon").classList.remove("loading-icon");

			runProgress(0.75);

			setTimeout(() => {
				loading.classList.add("d-none");
				var loading2 = document.querySelector(".loading.pull-request");
				loading2.classList.remove("d-none");
			}, 1500);

			createCommit(accessToken, project, repoId, objectId, userId);
		});
}

export async function createPullRequest(accessToken, repoId, userId, project) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/repository/pullrequest/create`,
			{
				repoId: repoId,
				userId: userId,
				fromBranch: "main",
				toBranch: "release"
			},
			{ headers: headers }
		)
		.then(() => {
			var loading = document.querySelector(".loading.pull-request");
			if (loading !== null) {
				loading.classList.remove("loading");
				loading.classList.add("success");
				loading.querySelector(".loading-icon").classList.add("success-icon");
				loading.querySelector(".loading-icon").classList.remove("loading-icon");

				runProgress(0.875);

				createDatabase(accessToken, project);

				setTimeout(() => {
					loading.classList.add("d-none");
					var loading2 = document.querySelector(".loading.create-database");
					loading2.classList.remove("d-none");
				}, 1500);
			}
		});
}

export async function createCommit(accessToken, project, repoId, objectId, userId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};
	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/repository/commit`,
			{
				objectId: objectId,
				comment: "created custom globalsetting",
				pathToFile: "/Files/Files/GlobalSettings.Database.config",
				repoId: repoId,
				projectName: project.name
			},
			{ headers: headers }
		)
		.then(() => {
			getCommitId(accessToken, project, repoId, userId);
		});
}

export async function renameFile(accessToken, project, repoId, objectId, userId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	const toCamelCase = (str) => {
		const arr = str.split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join(" ");
		return str2.replace(/ /g, "");
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/repository/rename`,
			{
				objectId: objectId,
				comment: "renamed source file",
				pathToFile: "/" + toCamelCase(project.name) + ".sln",
				repoId: repoId,
				projectName: project.name
			},
			{ headers: headers }
		)
		.then(() => {
			createPullRequest(accessToken, repoId, userId, project);
		});
}

export async function getCommitId(accessToken, project, repoId, userId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.get(`${process.env.REACT_APP_WEB_API}/repository/${project.name}/${repoId}`, {
			headers: headers
		})
		.then((response) => {
			renameFile(accessToken, project, repoId, response.data, userId);
		});
}
