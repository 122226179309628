import axios from "axios";
import { getRepositoryId } from "./Repository";
import { importProject } from "./Git";
import { sendMail } from "./Mail";
import { runProgress } from "../Progress";

export async function createProject(accessToken, project, account) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};
	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/projects/create`,
			{
				projectName: project.project.name,
				projectDescription: project.project.description,
				processTemplateId: `${process.env.REACT_APP_PROCESS_TEMPLATE}`,
				projectType: project.project.type,
				testServer: project.project.server,
				testServerUrl: project.project.testServerUrl
			},
			{ headers: headers }
		)
		.then(() => {
			// We need to wait 5 sek to ensure that the project has been created all the way.
			setTimeout(() => {
				// Get Repository ID
				if (project.project.projectType !== "Custom Project with empty repository") {
					getRepositoryId(accessToken, project.project, account);
				} else {
					// remove loading overlay
					document.querySelector(".loading-overlay").remove();
					document.querySelector(".js-final-btn").click();
				}
				// SEND MAIL
				sendMail(project.project, account);
			}, 5000);
		});
}

export async function getProject(accessToken, project, repoId, account) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	axios
		.get(`${process.env.REACT_APP_WEB_API}/projects/${project.name}`, {
			headers: headers
		})
		.then((response) => {
			var loading = document.querySelector(".loading.project");
			loading.classList.remove("loading");
			loading.classList.add("success");
			loading.querySelector(".loading-icon").classList.add("success-icon");
			loading.querySelector(".loading-icon").classList.remove("loading-icon");

			runProgress(0.125);

			setTimeout(() => {
				loading.classList.add("d-none");
				var loading2 = document.querySelector(".loading.repo");
				loading2.classList.remove("d-none");
			}, 1500);
			// Get Repository Data
			// Import project
			importProject(accessToken, project, repoId, response.data, account);
		});
}
