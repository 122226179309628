import axios from "axios";
import { runProgress } from "../Progress";
import { createBranch } from "./Repository";

export async function createRelease(
	accessToken,
	project,
	deploymentGroupId,
	repoId,
	projectId,
	buildId,
	objectId,
	account
) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};
	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/deployment/release/create/${deploymentGroupId}`,
			{
				projectName: project.name,
				testServer: "azure-test001",
				testServerUrl: project.testServerUrl,
				projectId: projectId,
				buildId: buildId,
				userMail: account.username
			},
			{ headers: headers }
		)
		.then((response) => {
			var loading = document.querySelector(".loading.release");
			loading.classList.remove("loading");
			loading.classList.add("success");
			loading.querySelector(".loading-icon").classList.add("success-icon");
			loading.querySelector(".loading-icon").classList.remove("loading-icon");

			runProgress(0.625);

			setTimeout(() => {
				loading.classList.add("d-none");
				var loading2 = document.querySelector(".loading.branch");
				loading2.classList.remove("d-none");
			}, 3000);

			createBranch(accessToken, repoId, project, objectId, response.data);
		});
}
