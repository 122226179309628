import axios from "axios";
import { runBuild } from "./Build";

export async function createDatabase(accessToken, project) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json"
	};

	const toCamelCase = (str) => {
		const arr = str.split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join(" ");
		return str2.replace(/ /g, "");
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/database/create?database=Dynamicweb_${toCamelCase(project.name)}`,
			{},
			{ headers: headers }
		)
		.then(() => {
			installSwift(accessToken, project);
		});
}

export async function installSwift(accessToken, project) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json"
	};

	const toCamelCase = (str) => {
		const arr = str.split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join(" ");
		return str2.replace(/ /g, "");
	};

	axios
		.post(
			`${process.env.REACT_APP_WEB_API}/database/install/swift?database=Dynamicweb_${toCamelCase(project.name)}&version=${project.swiftVersion}`,
			project.swiftVersion,
			{},
			{ headers: headers }
		)
		.then(() => {
			runBuild(accessToken, project);
		});
}
